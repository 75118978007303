@tailwind base;
@tailwind components;
@tailwind utilities;
.day-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 12px;
    background-color: #f3f4f6;
    color: #333;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .day-btn.selected {
    background-color: #cc1c1c; /* Dark red for the selected day */
    color: #fff;
  }
  
  .day-name {
    font-weight: bold;
  }
  
  .day-number {
    font-size: 12px;
    margin-top: 4px;
  }
  
  
  .table-container {
    margin-top: 20px;
  }
  